.form-center {
  display: flex;
  align-items: center;
}

.form-buttons {
  padding-top: 12px;
  display: flex;
  justify-content: flex-end;
}

.form-buttons .ant-btn + .ant-btn {
  margin-left: 8px;
}

.w-full {
  width: 100%;
}

.sale-tax {
  margin-top: -10px;
  display: flex;
  flex-direction: column;
}

.sale-tax .form {
  flex: none;
}

.sale-tax .statistics {
  flex: none;
  margin-top: 20px;
}

.sale-tax .table {
  flex: 1;
}

.sale-tax .ant-row .ant-form-item {
  margin-bottom: 0;
}
